var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-agency-add animatedBox"},[_c('div',{staticClass:"container fluid"},[_c('fd-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)},"error":_vm.validateFail}},[_c('div',{staticClass:"card p-2 mb-2"},[_c('h4',{staticClass:"mainTitle pt-3 pb-4"},[_vm._v("Add New Agency")]),_c('div',{staticClass:"row"},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Agency Name","name":"name","type":"text","validators":[
              _vm.validator.required,
              function (val) { return _vm.validator.minLength(val, 3); }
            ]},model:{value:(_vm.newAgency.name),callback:function ($$v) {_vm.$set(_vm.newAgency, "name", $$v)},expression:"newAgency.name"}}),_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Person In Charge","name":"personInChargeName","type":"text","validators":[
              _vm.validator.required,
              function (val) { return _vm.validator.minLength(val, 3); }
            ]},model:{value:(_vm.newAgency.personInChargeName),callback:function ($$v) {_vm.$set(_vm.newAgency, "personInChargeName", $$v)},expression:"newAgency.personInChargeName"}}),_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Person In Charge Contact","name":"personInChargeContact","type":"text","validators":[_vm.validator.required, _vm.validator.phone]},model:{value:(_vm.newAgency.personInChargeContact),callback:function ($$v) {_vm.$set(_vm.newAgency, "personInChargeContact", $$v)},expression:"newAgency.personInChargeContact"}}),_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Website","name":"website","type":"text","validators":[_vm.validator.website]},model:{value:(_vm.newAgency.website),callback:function ($$v) {_vm.$set(_vm.newAgency, "website", $$v)},expression:"newAgency.website"}}),_c('div',{staticClass:"col-12 px-1 mb-2 main-photo"},[_c('label',{staticClass:"label"},[_vm._v("Agency Logo")]),_c('image-uploader',{attrs:{"multiple":false},on:{"error":function (error, imageName) {
                  _vm.$reportError(error, 'Upload logo (Add Agency)');
                }},model:{value:(_vm.newAgency.logo),callback:function ($$v) {_vm.$set(_vm.newAgency, "logo", $$v)},expression:"newAgency.logo"}})],1)],1),_c('div',{staticClass:"text-right p-2"},[_c('button',{staticClass:"btn main"},[_vm._v("Create")])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }